<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
            <h1 class="text-center">{{ $t('users.all-users') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col class="px-4">
          <b-overlay :show="showOverlay">
            <b-col class="py-3">
              <b-link v-for="user in users" :key="user.id" :class="'my-3 d-flex align-items-center enabled-' + user.enabled" :to="{ name : 'EditUser', params: { username: user.email }}">
                <b-avatar v-if="user.logo !== null || user.logo !==''" variant="primary" class="mr-4" :src="user.logo" ></b-avatar>
                <b-avatar v-else :src="require('@/assets/img/users/image-not-available.png')" ></b-avatar>
                <h4 class="mr-4">{{ user.contactPerson }}</h4>
                <b-badge variant="info" class="mr-3">{{ user.enterpriseShortName | removeLat | abb(50) }}</b-badge>
                <b-badge variant="light">{{user.role.name}}</b-badge>
              </b-link>
            </b-col>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import { BAvatar, BBadge } from "bootstrap-vue";

export default {
  name : "AllUsers",
  components: { NavBar, BAvatar, BBadge },
  data: function() {
    return {
      users: {},
      showOverlay: true
    }
  },
  created: function() {
    this.$http.get('/cms/api/users/all', { headers: {Authorization: this.getAuthData().accessToken }})
    .then(r => {
      this.users = r.data;
    }).catch(e => { this.handleError(e); })
    this.showOverlay = false;
  }
}
</script>
<style></style>
